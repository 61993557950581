import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import { replaceDashWithSpace } from "../lib/StringHelper";
import Strings from "../constants/Strings";
import Colors from "../constants/Colors";

const Tags = (props) => {
  const { data, pageContext } = props;
  const posts = data.allMarkdownRemark.edges;
  const { tag } = pageContext;
  const tagNormalized = replaceDashWithSpace(tag);

  return (
    <Layout override="marginStandard">
      <SEO title={`${Strings.Tags.Title} "${tagNormalized}"`} />
      <div style={styles.textContainer}>
        <p
          style={styles.titleText}
        >{`${Strings.Tags.Title} (${posts.length}):`}</p>
        <p style={styles.subtitleText}>{tagNormalized.toUpperCase()}</p>
      </div>

      <div className="marginFocus" style={styles.container}>
        {posts.map(({ node }, index) => (
          <div />
        ))}
      </div>
    </Layout>
  );
};

const styles = {
  container: {
    marginBottom: "4.0rem",
  },
  titleText: {
    textAlign: "center",
    marginBottom: "0px",
    color: Colors.gray2,
    fontSize: "0.9rem",
  },
  subtitleText: {
    textAlign: "center",
    fontSize: "0.9rem",
  },
  textContainer: {
    borderBottom: "solid 1px #E6E7E8",
    marginBottom: "1.0rem",
  },
};

export default Tags;

export const query = graphql`
  query TagsQuery($tag: String!) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { eq: $tag } } }
    ) {
      edges {
        node {
          frontmatter {
            date(formatString: "MM.DD.YYYY")
            title
            tags
          }
          fields {
            slug
          }
          rawMarkdownBody
        }
      }
    }
  }
`;
